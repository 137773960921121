import { Box } from '@chakra-ui/react'
import { graphql } from 'gatsby'
import React from 'react'
import {
  CtaBlock,
  FeaturesBlock,
  FindBlock,
  ProductsBlock,
} from 'starterComponents'
import { useAuth } from 'contexts/AuthContext'

import useNeedsMembership from "../../hooks/useNeedsMembership"
import Loader from "../../components/ui-components/Loader"

const DashboardPage = (props) => {
  const {
    data: {
      page: {
        flexLayouts: { flexibleLayouts },
      },
    },
  } = props

  const showLoaderPage = useNeedsMembership()


  const { currentUser } = useAuth()
  const onWarrantyProgram = currentUser?.shopData?.code === "S" || currentUser?.shopData?.warrantyProgram;
  const subtitle = currentUser ? (onWarrantyProgram ? "Status: <span>Active</span>" : "Status: <span>Disabled</span>") : props.subtitle;

  if (showLoaderPage) {
    return <Loader />
  }

  return (
    <Box className="py-12 bg-light">
      <>
        {flexibleLayouts?.map((block, index) => {
          switch (block.__typename) {
            case 'WpPage_Flexlayouts_FlexibleLayouts_FeaturesBlock':
              return <FeaturesBlock key={index} {...block} />
            case 'WpPage_Flexlayouts_FlexibleLayouts_ProductsBlock':
              return <ProductsBlock {...block} key={index} />
            case 'WpPage_Flexlayouts_FlexibleLayouts_CtaBlock':
              if (block.title === "ATSG <strong>ADVANTAGE WARRANTY</strong>") {
                return <CtaBlock {...block} key={index} subtitle={subtitle} />
              }
              return <CtaBlock {...block} key={index} />
            case 'WpPage_Flexlayouts_FlexibleLayouts_FindBlock':
              return <FindBlock {...block} key={index} />

            default:
              return ''
          }
        })}
      </>
    </Box>
  )
}
export const DashboardQuery = graphql`
  query WpPageDashboardQuery($id: String!) {
    page: wpPage(id: { eq: $id }) {
      flexLayouts {
        flexibleLayouts {
          __typename
          ...ctaBlockFragment
          ...featuresBlockFragment
          ...findBlockFragment
          ...productsBlockFragment
        }
      }
    }
  }
`

export default DashboardPage
